import { Outlet } from "react-router-dom";
import CustomNavbar from "./MainNavbar";

export function ParentWrapper () {
    return (
        <>
        <CustomNavbar />
        <Outlet />
        </>
    )
} 