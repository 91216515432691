import './App.css';
import React from 'react';
import { createBrowserRouter, RouterProvider} from "react-router-dom";
import { Starthere } from "./Starthere";
import {Errorpage} from "./Error";
import HomePage from './Pages/HomePage';
import { Resources } from './Resources';
import {ParentWrapper} from './ParentWrapper';
import {Bookings} from './Pages/Bookings';

const router = createBrowserRouter([
  {path: '/',
  element: <ParentWrapper />,
  errorElement: <Errorpage />,
  children: [
  {path: '/', element: <HomePage />},
  {path: '/Starthere', element: <Starthere />},
  {path: '/Resources', element: <Resources />},
  {path: '/Bookings', element: <Bookings />},
],
}
]);


function App() {
  return (
  <RouterProvider router = {router}/>

    );
}

export default App;
