import React from 'react';
import './Resources.css'
import {Container, Navbar, Nav, Row, Col} from 'react-bootstrap';
import hiraganaChart from './images/hiraganachart.png';
import katakanaChart from './images/katakanachart.png';

export function Resources () {
    return(
        <>
        <Container>
        <Row>
      <Col sm={0} xl={3}>
      </Col>
        <Col sm={12} xl={6}>
        <br></br>
        <br></br>
        <h3>Resources:</h3>
        <br></br>
        <br></br>
        <p>The charts below contain the Japanese alphabet and the associated pronounciations for each character. Read vertically from right to left, 
             improve your recognition skills by using the chart to decipher text.</p>
        <br></br>
        <h4>Hiragana Chart (Basic alphabet)</h4>
            <div>
                <img src={hiraganaChart} alt="tanoshii" className='img-fluid shadow-4' style={{width: "1000px"}}/>
            </div>        
            <br></br>
            <br></br>
        <h4>Katakana Chart (Alphabet used to write loan words)</h4>
            <div>
               <img src={katakanaChart} alt="tanoshii" className='img-fluid shadow-4' style={{width: "1000px"}}/>
            </div>        
            <br></br>
            <br></br>
        <h3>NHK links:</h3>
        <br></br>
        <p>Japan Broadcasting Corporation or Nippon Hoso Kyokai, better known as NHK is Japan's national public broadcaster. The following links
            contain material from NHK that will aid you in understanding written and spoken Japanese. 
        </p>
        <p><a href='https://www3.nhk.or.jp/news/easy/'>NHK News Web Easy</a> is a beginner friendly way to access the latest news in Japanese. With bite-sized content and built in features to make 
            difficult words and characters easier to understand, this is a popular way to practice reading Japanese.</p>
            <br></br>
        <p><a href='https://www.nhk.or.jp/school/'> NHK for school</a> contains a vast library of short videos designed to educate students on various subjects at a range of difficulty levels. The on-demand site contains 
            the episodes from the programs that are televised on NHK's sister channel NHK Educational TV in Japan.   
        </p>
        </Col>
        </Row>
        </Container>

    <div className="footer"> 
    <p>© Sydney Japanese</p>
    </div>

    </>

    )
}