import {Container, Row, Col} from 'react-bootstrap';
import './Error.css'; 
export function Errorpage () {
    return(
        <>
        <Container>
            <Row>
                <Col sm={0} xl={5}></Col>
        <div className='errorMsg'>
        <h1> How did you get here? This page doesn't exist!</h1>
        <p> Click <a href="https://sydneyjapanese.com"> here</a> to get back home</p>
        </div>
        </Row>
        </Container>
        </>
    )
}
