import React from 'react';
import {Container, Navbar, Nav, Row, Col} from 'react-bootstrap';


export function Starthere () {
  return (
    <>
    
    <Container>
      <Row>
      <Col sm={0} xl={3}>
      </Col>
        <Col sm={12} xl={6}>
          <br></br>
          <div className='starthereArticle'>
          <br></br>
<br></br>

           <h3>Not your typical Japanese lesson.</h3>
          <ul></ul>
          <p>

<br></br>
<br></br>

Forget about traditional "follow the textbook" learning. While other classes take a linear approach, Sydney Japanese will immerse you in an environment of rapid fire language exposure.
With Sydney Japanese you won't worry about "missing something" because it will come around again before you know it.
<br></br>
<br></br>
All you have to do is keep your eyes open and focused on the road ahead. We don't even want you to take notes, just follow the cues from your teacher. Did we mention your teacher will only speak in Japanese during the class?
But don't worry, we know this is all new to you!

            </p>
<br></br>
<br></br>

<h3>Goals and outcomes</h3>
<br></br>
<br></br>

<p>
1) Recognise Written Japanese (in particular you will be able to differentiate Japanese text from other Asian languages).
<br></br>
<br></br>
2) Identify spoken Japanese (you will be able to tell when someone is speaking Japanese).
<br></br>
<br></br>
3) You will understand the basic structure and usage of the 3 alphabets used in Japanese (hiragana, katakana, kanji)
<br></br>
<br></br>
4) Read Hiragana (you will be able to read basic sentences in hiragana and make sense of place names etc written in this alphabet).
<br></br>
<br></br>
5) Read Katakana (you will be able to sound out words written in katakana).
<br></br>
<br></br>
6) You will recognise and recall the meaning of common kanji appearing on signs, menu's etc. </p>
<br></br>
If you don't know the answer, don't worry... you aren't the first student to not know the answer - It's OK! The class will simply keep going.
You don't have to get everything right each time, in fact sometimes it might feel like you aren't getting anything right. This struggle is the
 key to real learning, as your brain goes into overdrive trying to make sense of all this "foreign" stimuli. It's not about getting things perfect, it's about improving. 

<br></br>
<br></br>
<br></br>


 <h3>What no homework???</h3>

<br></br>
<br></br>

<p>
There are lot's of things you can do at home to speed up your learning, if you have the time and motivation to pursue them.
Study apps, workbooks, flashcards, youtube, television, movies, internet radio and music are all great ways to accelerate your exposure.
You might even want to enrol in a traditional language course focused on Japanese grammar.
These are all fantastic options but they are not essential for beginners. We don't assign homework.
</p>

         </div>
         </Col>
      </Row> 
    </Container>

    <div className="footer"> 
      <p>© Sydney Japanese</p>
      </div>
    </>
    )
}
