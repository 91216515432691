import {Container, Navbar, Nav} from 'react-bootstrap';

function CustomNavbar() {
    return (
    <Navbar bg="light" data-bs-theme="light" style={{width: "100%"}}>
    <Container>
      <Navbar.Brand href="https://sydneyjapanese.com"> Sydney Japanese</Navbar.Brand>
      <Nav className="me-auto">
        <Nav.Link href="https://sydneyjapanese.com/Starthere">Get started</Nav.Link>
        <Nav.Link href="https://sydneyjapanese.com/bookings">Book a lesson</Nav.Link>
        <Nav.Link href="https://sydneyjapanese.com/resources">Resources</Nav.Link>
      </Nav>
    </Container>
  </Navbar>
  )
}

export default CustomNavbar;