import React from 'react';
import Table from 'react-bootstrap/Table';
import {Container, Row, Col} from 'react-bootstrap';


export function Bookings () {
  return (
    <>
    
    <Container>
      <Row>
      <Col sm={2} xl={3}>
      </Col>
        <Col sm={8} xl={6}>
          <div className='bookaClass'>
          <br></br>
          <br></br>
           <h3>Find a Class For You.</h3>
           <br></br>
          <br></br>
          <p> 
            Join fellow adult students, in a 10 week program that runs over the school term.
            <br></br>
            <br></br>
            Program length: 1 hour a week over the 10 week school term. 
            <br></br>
            <br></br>
            Program cost: $300 per 10 week term ($30 a week).
          </p>

<br></br>
<br></br>

<Table striped bordered hover>
      <thead>
        <tr>
          <th>Day</th>
          <th>Location</th>
          <th>Time</th>
          <th>Make Booking</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>Friday</td>
          <td>Chatswood Concourse Room 1</td>
          <td>12pm-1pm</td>
          <td><a href=''>Book Here</a></td>
        </tr>
        <tr>
          <td>Friday</td>
          <td>Chatswood Concourse Room 1</td>
          <td>1pm-2pm</td>
          <td><a href=''>Book Here</a></td>
        </tr>
    </tbody>
</Table>

    <p>*In the event that you cannot attend a lesson, no refund will be be given. </p>
    </div>
    </Col>
    </Row> 
    </Container>

    <div className="footer"> 
      <p>© Sydney Japanese</p>
      </div>
    </>
    )
}

