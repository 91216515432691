import '../App.css';
import React from 'react';
import {Container, Navbar, Nav, Row, Col} from 'react-bootstrap';
import confettiImage from '../Confettipng.png';
import disp1 from '../images/tanoshii1.png';
import disp2 from '../images/kanntann1.png';
import disp3 from '../images/nihongo1.png';
import herobanner from '../images/SJherobanner.png';
import confetti from '../images/Confetti.png';



export function App() {
    return (
      <>
        <br></br>
        <br></br>
      <Container>
        <Row className ="heroLanding">
          <Col xl={12}>
          <div className="confetti">
          <img src={confetti} alt="confetti" className='img-fluid shadow-4' style={{width: "95%"}}/>
           </div>
          <div className='herobannertext'>
           <h1>A new way of learning Japanese</h1>
           </div>
          </Col>
        </Row>
      </Container>
          <br></br>
  
      <Container>
      <Row className="align-items-center">
          <Col sm={12} xl={6}>
            <div className='learningmethodText'>
             <h3>Fun. Interactive. Useful.</h3>
            <ul></ul>
            <p>Forget about rote learning words and taking down notes. With our high-participation, 
              high-exposure learning style, we'll have you remembering words before you even know it. 
              <br></br><br></br>
              Rather than training students to pass tests, we believe in giving students the tools they need 
              to recognise Japanese and communicate using everyday language. Learn useful characters, words, phrases
              and more to read and speak the language fast. 
              </p>
           </div>
           <br></br>

           </Col>           
           <Col sm={0} xl={6}>
             <div className="heroShot">
               <div className="sideImg1">
                <img src={disp1} alt="tanoshii" style={{width: "350px"}}/>
              </div>
             </div>
             </Col>
        </Row> 
      </Container>
  
      
  <br></br>
  <br></br>
  <br></br>
  <br></br>


  
  <Container>
  <Row className="align-items-center">
  <Col sm={12} xl={6}>
            <div className='sideP2'>
            <h2>Learning made easy</h2>
            <br></br>
            <p> We've handpicked our lesson content to get you exposed to the language used in real situations as quickly as possible.
              We prioritise our students being able to use many new phrases well enough rather than only being able to recite
              a handful of phrases perfectly. Perfection is the enemy of progress.    
            </p>
           </div>
           <br></br>
          </Col>
        <Col sm={0} xl={6}>
             <div className="heroShot">
               <div className="sideImg2">
                <img src={disp2} alt="Kantann" style={{width: "350px"}}/>
              </div>
             </div>
             </Col>
        </Row> 
      </Container>
  
      <br></br>
  <br></br>
  <br></br>
  <br></br>


  <Container>
        <Row className="align-items-center">
          <Col sm={12} xl={6}>
            <div className='sideP3'>
            <h2>Talk like you're in Japan</h2>
            <br></br>
            <p> Imagine you were in Japan right now. Nearly everyone would be speaking Japanese. Once our classes start
              we only use Japanese, giving you the opportunity to immerse yourself in a real Japanese speaking-environment. 
            </p>
           </div>
           <br></br>
          </Col>   
          <Col sm={0} xl={6}>
             <div className="heroShot">
               <div className="sideImg3">
                <img src={disp3} alt="nihongo" style={{width: "350px"}}/>
              </div>
             </div>             
             </Col>
        </Row>
      </Container>

  <br></br>
  <br></br>
  <br></br>
  
  <Container>
     <Row className="align-items-center2">
          <Col sm={12}>
          <div  class="text-center">  
          <a href='https://sydneyjapanese.com/starthere'>
          <button className="getstartedButton" >
           Get started!
          </button>
          </a>
          </div>
          </Col>
        </Row>
      </Container>
        
      <div className="footer">          

        <p>© Sydney Japanese</p>
        </div>
      </>
      );
  }
  
  export default App;
  